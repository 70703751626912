import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw, RouterOptions} from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'index',
        component: () => import(/* webpackChunkName: "indexPage" */ '../views/home/index.vue')
    },
    {
        path: '/tutorial',
        name: 'video',
        component: () => import(/* webpackChunkName: "videoPage" */ '../views/video/index.vue')
    },
    {
        // 匹配未定义的路由，跳转回首页
        path: '/:pathMatch(.*)',
        name: 'redirectToHome',
        redirect: '/',
    }
]

const router = createRouter(<RouterOptions>{
    routes,
    history: createWebHashHistory(),
})

export default router
